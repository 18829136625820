import * as React from "react";

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";
import HomePage from "./Home";
import Blog from "./Blog";
import Art from "./Art/Art";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <HomePage/>
        ),
    },
    {
        path: "/art",
        element: (
            <Art/>
        ),
    },
    {
        path: "blog",
        element: (<Blog/>),
    },
]);

function App() {
    return (
        <RouterProvider router={router} />
    )
}

export default App;