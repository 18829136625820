import {useEffect} from "react";
import {ARTSTATIONURL} from "../Constants";

const Art = () => {
    const redirectToArtstation = () => {
            window.location.href = ARTSTATIONURL;
    }

    useEffect(() => {
        redirectToArtstation()
    } , [])
    return (
        <>

        </>
    )
}

export default Art;