export const LINKEDIN_URL = "https://www.linkedin.com/in/kawaljeetsinghbatra/";
export const GITHUB_URL = "https://github.com/Kawaljeet2001";
export const RESUME_URL = "https://drive.google.com/file/d/1pZYgSNmgUoR2epgbATO2ORqIQtig52x8/view?usp=sharing";
export const INSTAGRAM_URL = "https://www.instagram.com/kawaljeet_sb/";
export const EMAIL_URL = "mailto:batrakawaljeetsingh@gmail.com";
export const ARTSTATIONURL = "https://www.artstation.com/kawal2001singh"
export const LOCATION_URL = "https://goo.gl/maps/RaDYVrVw9Vhz8amA9";
export const PROFILE_PHOTO_URL = "https://firebasestorage.googleapis.com/v0/b/artistify-42678.appspot.com/o/profile.jpeg?alt=media&token=a63082ab-00e9-428d-a377-3b42dde8aadc";
export const LEETCODE_URL = "https://leetcode.com/batrakawaljeetsingh/";
export const GEEKSFORGEEKS_URL = "https://auth.geeksforgeeks.org/user/kawaljeet/profile";
export const HACKERRANK_URL = "https://www.hackerrank.com/batrakawaljeets1?hr_r=1";