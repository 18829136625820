
const Blog = () => {
    return (
        <div>
            <h1>This is the blogPage</h1>
        </div>
    )
}

export default Blog;



//we need a listview for the blog
//we need detailpage
    // detail page should be able to support tinymce type editor for the blogs
// we need an admin view for the blog
//need to sort by date for storing/viewing it
    // can include pagination (not required in v1)
// create a firebase collection for blogpost
    // also a collection for comments on the blogpost
        //include replies and like for the comments
        //mention the user's name also in the comment
        //mention these comments based on date